/* Header.css */
.header {
  background-color: var(--background-header-color) !important;
  transition: all 0.4s ease;

  div[class*="MuiToolbar-root"] {
    min-height: -webkit-fill-available;
  }
}

.logo {
  cursor: pointer;
  height: 60%;
  transition: height 0.4s ease;
}

.headerContainer {
  top: 0;
  position: sticky;
  z-index: 1000;
  width: 100%;
  transition: height 0.5s ease;
  display: flex;
  align-items: center;
  box-shadow: 0 3px 6px -1px rgba(255, 255, 255, 0.3);
}

.navMenu {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  align-items: center;
}

.navItem {
  color: #ffffff;
  cursor: pointer;
  padding: 10px 15px;
  border-radius: 5px;
  position: relative;
  transition: background-color 0.3s ease;
  user-select: none;
}

.navItem.selected {
  color: #d71d33;
}

.navItem::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #d71d33;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.3s ease;
}

.navItem:hover::after {
  transform: scaleX(1);
}

.navItem:not(.selected):hover::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  height: 2px;
  width: 100%;
  background: #d71d33;
}
