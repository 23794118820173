.empresaCard {
  margin: 2em auto;
  width: 80%;
  background-color: #f0f0f0;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  user-select: none;
}

.empresaContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.empresaTextContent {
  flex: 1;
  padding: 2em;
}

.empresaImage {
  flex: 1;
  max-width: 100%;
  border: 2px solid black;
  border-radius: 5px;
}

.empresaTitle {
  margin-bottom: 1em;
}

.empresaText {
  margin-top: 1em;
}

.slick-dots.custom-dots {
  width: fit-content;
  position: unset;
  display: flex !important;
  justify-content: center;
  padding: 0;
}

.slick-dots.custom-dots li {
  width: 60px;
  height: 60px;
}

@media screen and (min-width: 768px) {
  .slick-dots.custom-dots li {
    margin: 5px 5px;
    width: 75px;
    height: 75px;
  }
}

.slick-dots.custom-dots li button {
  width: 60px;
  height: 60px;
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;

  ::before {
    display: none !important;
  }
}

.slick-dots li button {
  width: 100%;
  height: 100%;
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
}

.slick-dots.custom-dots li button img {
  width: 100%;
  height: 100%;
  border-radius: 10%;
  border: 2px solid transparent;
  transition: border-color 0.3s;
}

.slick-dots.custom-dots li.slick-active button img {
  border-color: #fa130d;
}

.sliderContainer {
  margin: 0 auto;
}

.slick-dots li button:before {
  display: none;
}

.slick-track {
  display: flex !important;
  /* align-items: flex-end !important; */
}

.thumbnailContainer {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  padding: 10px 0;
  margin-top: 10px;
  justify-content: space-between;
}

.thumbnailWrapper {
  display: inline-block;
  margin: 0 5px;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}

.thumbnailWrapper:hover {
  transform: scale(1.05);
}

.thumbnailWrapper.active img {
  border: 2px solid #d71d33;
}

.thumbnailWrapper img {
  border: 2px solid transparent;
  transition: border-color 0.3s;
}