@import url("./Empresa.css");
@import url("./Contact.css");
@import url("./Header.css");
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

:root {
  --background-header-color: rgb(0, 0, 0);
}

body {
  margin: 0;
  padding: 0;

  p,
  text,
  h1,
  div,
  label {
    font-family: "Roboto" !important;
  }
}

::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

* {
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
