.introContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--background-header-color);
  z-index: 1000;
  overflow: hidden;
}

.logoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.introLogo {
  width: auto;
  height: auto;
}
